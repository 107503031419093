import React from "react";
import logoRodape from "../img/logo-min.png";
import logoPlayStore from "../img/play-store-min.png";
import logoAppleStore from "../img/apple-store-min.png";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer font-small unique-color-dark gza-bg-gray mw-pad-t-15">

                <div className="container text-center text-md-left mt-5 mw-pad-l-0 mw-pad-r-0">
                    <div className="row mt-3">
                        <div className="col-md-6 mw-pad-l0 mw-pad-r-0">
                            <div className="row">
                                <div className="col-md-4 mw-m-l-0 mw-pad-l-0 mx-auto mb-4">
                                    <div className="mw-a-items-center mw-fit-height mw-flex mw-flex-justify-center">
                                        <img src={logoRodape}/>
                                    </div>
                                </div>
                                <div className="col-md-4 mw-m-l-0 mx-auto mb-4">
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Gazeta de Alagoas</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Classificados Gazeta Online</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">TV Gazeta de Alagoas</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Gazeta FM Maceió</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Gazeta FM Arapiraca</a></p>
                                </div>
                                <div className="col-md-4 mw-pad-l-0 mx-auto mb-4">
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">94 FM Maceió</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Gazetaweb.com</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">TV Mar</a></p>
                                    <p className="mw-m-b-10 gza-links-footer"><a className='gza-c-gray-2' href="" title="">Organização Arnon de Mello (Institucional)</a></p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mw-pad-l0 mw-pad-r-0">
                            <div className="row">
                                <div className="col-md-6 mw-pad-l-0">
                                    <article className="mw-m-b-30 gza-f-14 gza-f-roboto-light">
                                        <h3 className="gza-f-roboto-bold gza-f-16 mw-m-b-5">Contato comercial</h3>
                                        <p className="gza-f-lg-12 mw-m-0">(82) 4009 7755</p>
                                        <p className="gza-f-lg-12 mw-m-0">comercialga@gazetadealagoas.com</p>
                                    </article>
                                    <article className="mw-m-b-30 gza-f-14 gza-f-roboto-light">
                                        <h3 className="gza-f-roboto-bold gza-f-16 mw-m-b-5">Redação</h3>
                                        <p className="gza-f-lg-12 mw-m-0">(82) 4009 7853</p>
                                        <p className="gza-f-lg-12 mw-m-0">noticias@gazetadealagoas.com</p>
                                    </article>
                                </div>
                                <div className="col-md-6 mw-pad-l-0">
                                    <h3 className="gza-f-roboto-bold gza-f-16 mw-m-b-10">Baixe nosso <br/> aplicativo</h3>
                                    <img src={logoPlayStore} className="mw-pad-b-10" />
                                    <img src={logoAppleStore} />
                                </div>
                            </div>
                        </div>
                    
                    </div>

                </div>
                <div className="footer-copyright py-3 gza-bg-white">
                    <div className="container mw-pad-l-0">
                        <b className="gza-f-roboto-bold">Gazeta de Alagoas Online Ltda</b> &#9400; 2009 - Todos os direitos reservados
                    </div>
                </div>

            </footer>
                )
                }
        }
