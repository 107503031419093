import React from "react";
import { SketchField, Tools } from "react-sketch";
import { baseUrl, baseHub } from "../utils/url";
import {Container,Col,Row,} from "reactstrap";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import ListMarkers from "../components/ListMarkers";
import ListTools from "../components/ListTools";
import ListSetValues from "../components/ListSetValues";

class Editor extends React.Component {
  state = {
    editin: false, //estado para saber se está no modo edição ou não
    selectedTool: Tools.Select, //tipo de marcação a ser feita (no caso só tem retângulo)
    page: { pageId: null, elements: [] }, //armazena o número da página e suas marcações
    articles: [], //estado que ficam os artigos
    categories: [], //estado que ficam as categorias
    markings: null,
    markingsFull: null,
    titles: [],
  };

  //setando o valor do redirect
  setRedirect = () => {
    this.setState({ redirect: true });
  };

  setPage = (page) => {
    this.setState({ page });
  }

  // obtendo dimensões da imagem
  getImageSize = dimension => {
    // const { pathUrl } = this.props;
    var image = document.getElementById("image");

    if (dimension === "width") {
      return !!image && image.width;
    } else {
      return !!image && image.height;
    }
  };

  //setando alteração do tipo de marcação
  setTool = () => {
    if (this.state.editin) {
      this.setState({ selectedTool: Tools.Select });
    } else {
      this.setState({ selectedTool: Tools.Rectangle });
    }
  };

  //setando o estado da edição
  setEditin = () => {
    this.setState({ editin: !this.state.editin });

    this.setTool();
  };

  //excluir o objeto selecionado
  deleteObject = (id) => {
    const {markings, markingsFull} = this.state

    markingsFull.pages.forEach(page => {
      page.elements.forEach((v,i) => v.id === id &&  page.elements.splice(i, 1))
    })
    this.setState({markingsFull})

    markings.elements.forEach((v, i) => v.id === id &&  markings.elements.splice(i, 1))
    this.setState({markings})

    alert("Marcação Apagada com Sucesso")
  };

   //valor do tipo de marcação escolhido
   handleSelectedTool = e => {
    this.setState({
      selectedTool: e.target.value
    });
  };

  load = async(data) => {
    const {selectPageId} =  this.props
    console.log("dddd",data)
    const result = data.pages && data.pages.find(e => e.pageId == selectPageId);

    this.setState({markingsFull: data})
    this.setState({titles: result ? result : 0})
    this.setState({markings: result})

     //desenhando as marcações
      try{
     if(this.state.markings){
      let coord = []
      this.state.markings.elements.forEach(v => coord.push(v.coordinates))
      let canvas = this._sketch;
      //mostra as marcações de acordo com as coordenadas
      canvas.fromJSON({
        objects: coord
      });
    }
  }catch(e){
    console.log("error" + e)
  }
  }

  componentDidMount = async () => {
    const editionId = this.props.selectEdition;
    if (editionId) {
      //pegando a página vindo de app.js
      const { selectPageId } = this.props;
      //setando o número da página e as páginas existentes
      this.setState({ page: { ...this.state.page, pageId: selectPageId } });
      //carregando os artigos
      const articles = await Axios.get(
        `${baseUrl}/Page?editionId=${editionId}&pageNumber=${selectPageId}&format=json`
      );
      //setando os artigos em uma variável
      this.setState({ articles: articles.data });
      
      const {data} = await Axios.get(`${baseHub}/categories`)
      this.setState({ categories: data });

      /*
      this.state.articles.forEach(article => {
        this.getArticle(article.articleId);
      }) */
      try{
     //Pegando as marcações 
      const {data:{markingsUrlEditor}} = await Axios.get(`${baseHub}/c`)
      const {data} = await Axios.get(`${markingsUrlEditor}/${editionId}.json`);

      const elements = data.pages.find(e => e.pageId == selectPageId);
      
      this.setState({titles: elements ? elements : 0})
      this.setState({markings: elements})
      this.setState({markingsFull: data})

      //desenhando as marcações
      
        if(this.state.markings){
          let coord = []
          this.state.markings.elements.forEach(v => coord.push(v.coordinates))
          let canvas = this._sketch;
          //mostra as marcações de acordo com as coordenadas
          canvas.fromJSON({
            objects: coord
          });
        }
      }catch(e){
        console.log("error" + e)
      }
     } }
 

  //cria o objeto a ser armazenado no banco, reune todas as info necessárias e salva
  handleConvert = async () => {
    const editionId = this.props.selectEdition;
    let oldPages = this.state.markings

    let newPage= [this.state.page]
    var { width, height } = document.getElementById("image");

    newPage[0].dimensions = { width, height };

    let data
    let flagError = 0

    try{
      const {data:{markingsUrlEditor}} = await Axios.get(`${baseHub}/c`)
      const result = await Axios.get(`${markingsUrlEditor}/${editionId}.json`);
    } catch(e){
      flagError = 1
    }
    
    //Caso n exista o arquivo
    if(flagError){
      data = {
        editionId,
        pages: newPage
      }
    } else {
      //caso n exista marcação (oldPages == undefined)
      if(!oldPages){
        let newMarking = newPage
        let markingsFull = this.state.markingsFull
        markingsFull && markingsFull.pages.forEach(v => newMarking.push(v))
        
        data = {
          editionId,
          pages: newMarking
        }

      } else if(oldPages.pageId == newPage[0].pageId) {
        const markingsFull = this.state.markingsFull
        const oldMarking = oldPages
        const newMarking = newPage

        //Pegando tds as pages novas
        const novas = newMarking[0].elements.filter(v => !!v.title)

        oldMarking.elements.forEach(newM => {
          let result = novas.findIndex(x => x.id == newM.id)
          if(result == -1){
            novas.push(newM)
        }
        } ) 

        
        let objNewMarking = {
          pageId: newMarking[0].pageId, 
          elements: novas,
          dimensions: newMarking[0].dimensions}
          
          markingsFull.pages.forEach((v,i) => {
          if(v.pageId === oldMarking.pageId) {
            markingsFull.pages[i] = objNewMarking
          }
          } )
          
          data = markingsFull
        } 
    }

    try{
    await Axios.post("http://mundiware.com:3500/save-markers", data, {
      crossdomain: true
    });
  } catch(e){
    console.log("error" + e)
  }

  this.load(data)
    }

  //cada marcação no canvas, salvo em page
  handleChangeSketch = () => {
    const { objects } = this._sketch.toJSON();
    let newObjects = [];
    objects.map((v, i) => {
      newObjects[i] = {
        ...this.state.page.elements[i],
        id: v.type + String(v.left) + String(v.top),
        coordinates: v
      };
    });
    //setando o valor de page a cada marcação feita
    this.setState({
      page: { ...this.state.page, elements: newObjects }
    });
  };
/*
  getArticle = async id => {
    try {
      const editionId = this.props.selectEdition;
      const titleArticles = await Axios.get(
        `${baseUrl}/Article?editionId=${editionId}&articleId=${id}&format=json`
      );

      const { textHtml } = titleArticles.data;
      const result = textHtml.slice(0, 260);
      const titleSplit = result.split("<p class='ecs-root'>").join("");
      const title = titleSplit.split("</p>").join("");
      const data = { id, title };
      this.setState({ titlesPage: [...this.state.titlesPage, data] });
    } catch (e) {
      console.log(e);
    }
  };
*/
  render() {
    /*pathUrl: link da url da página escolhida
              pageTitle: títulos das marcações referente a página
            */

    const { selectPathUrlPage, selectEdition,selectPageId } = this.props;

    if (!selectEdition) return <Redirect to="/" />;
    return (
      
      <>
        <Header
          title="Edição"
          edit={this.setEditin}
          save={() => {
            this.handleConvert();
            this.setEditin();
          }}
          enableEdit={this.state.editin}
        />

        <Container className="mw-pad-t-30 mw-pad-b-30 mw-m-t-50">
          <Row className="mw-pad-b-30" style={{ minWidth: 1250 }}>
            <Col
              xs="3"
              className="mw-pad-l-0"
              style={{ borderRight: "1px solid #e8e8e8" }}
            >
            {!this.state.editin && <ListMarkers 
            titles={this.state.titles} 
            pageId={selectPageId} 
            editionId={selectEdition}/>} 
           
              {this.state.editin && (
                <>
                  <ListTools handleSelectedTool={this.handleSelectedTool} />
                  <br />
                  <br />
        
                  <ListSetValues
                    deleteObject={this.deleteObject}
                    page={this.state.page}
                    setPage={this.setPage}
                    markings={this.state.markings}
                    parseHtml={this.parseHtml}
                    categories={this.state.categories}
                  />
                  </>
                  )}
            </Col>
            <Col xs="8">
              {/*espaço para realizar a marcação */}
              <h5>Editor</h5>
              <hr />
              <img
                id="image"
                src={baseUrl + selectPathUrlPage}
                alt=""
                style={{
                  display: "block",
                  position: "absolute",
                  maxWidth: 737,
                  maxHeight: 1257,
                  width: "auto",
                  height: "auto"
                }}
              />
              <SketchField
                name="sketch"
                width={this.getImageSize("width")}
                height={this.getImageSize("height")}
                tool={this.state.selectedTool}
                lineColor="#0000"
                fillColor="#00b0adaa"
                style={{
                  backgroundColor: "#0000",
                  backgroundSize: "100%"
                }}
                lineWidth={3}
                ref={c => (this._sketch = c)}
                onChange={this.handleChangeSketch}
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default Editor;