import React from 'react'
import {
    Container,Col,
   Form, FormGroup, Input, Button
  } from 'reactstrap';
import Header from "./Header";
import Footer from "./Footer";
import {userGZA, passwordGZA} from "../utils/login"


export default class Login extends React.Component{
    state={
        user:null,
        password: null
    }

    handleUser = e => {
        this.setState({
          user: e.target.value
        });
      };

    handlePassword = e => {
        this.setState({
            password: e.target.value
        });
    };

    verify = () => {
        const {login} = this.props
        const {user, password} = this.state
        if(userGZA ===  user && passwordGZA == password){
            login()
        } else {
            alert("Credenciais Inválidas")
        }
    }
    
    render(){

        return(
            <>
                <Header />
                    <Container 
                    style={{marginTop: '80px', marginBottom: "20px", height: '100%', width:'30%', border: '2px solid #000'}}>
                        <Col sm="12" style={{textAlign: 'center'}}>
                            <h1>Login</h1>
                            <Form>
                            <FormGroup>
                            <Input
                            style={{borderColor: '#00b0ad', padding: '10px', margin: '10px'}}
                            type="text"
                            name="user"
                            id="user"
                            onChange={this.handleUser}
                            placeholder="Digite seu usuário"
                            />
                            <Input
                            style={{borderColor: '#00b0ad', padding: '10px', margin: '10px'}}
                            type="password"
                            name="password"
                            id="password"
                            onChange={this.handlePassword}
                            placeholder="Digite seu senha"
                            />
                            <Button 
                            color="success"
                            onClick={this.verify}
                            >Logar</Button>
                            </FormGroup>
                            </Form>
                        </Col>
                    </Container>
                <Footer />
            </>
        )
    }
}