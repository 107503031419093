import React from "react";
import { Card, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/url";

export default function CardPage({
  selectPage,
  setRedirect,
  number,
  image,
  section,
  name
}) {
  return (
    <Card
      body
      style={{ margin: "15px", cursor: "pointer" }}
      onClick={() => {
        selectPage(image, number);
        setRedirect();
      }}
    >
      <Link
        to="/editor"
        style={{
          width: "100%",
          height: "100%",
          padding: 10,
          textDecoration: "none"
        }}
      >
        <div>
          <img width="100%" src={`${baseUrl}${image}`} alt="" />
        </div>
        <div className="gza-bg-gray gza-c-gray-2 mw-pad-10">
          <CardTitle className="mw-m-0">
            <span className="gza-f-12 gza-c-white gza-bg-primary gza-b-radius-3px gza-pad-1-5">
              Seção: {section}
            </span>
          </CardTitle>
          <CardText>Nº da Página: {number}</CardText>
          {/*<CardText>Data da Edição: {date}</CardText> */}
        </div>
      </Link>
    </Card>
  );
}
