import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import Axios from "axios";
import classnames from "classnames";
import {baseHub} from "../../utils/url"
import SearchArticleDate from "../SearchArticleDate"
import EditoriasSearchArticle from "../EditoriasSearchArticle"
import SearchArticle from "../SearchArticle"

const renderArticles = (
  articles,
  changeTitle,
  id,
  parseHtml,
  changeValueArticle,
  toggle,
  type
) => articles &&
  articles.map(article => {
    return (
      <Card
        body
        onClick={toggle}
        key={type === "edition" ? article.id : article.ArticleId}
        style={{
          margin: "15px",
          cursor: "pointer",
          border: "2px solid #d3d3d3"
        }}
      >
        <Col sm="12">
          {/*onChangeValueArticle: armazenar o link do artigo */}
         
          <p
            onClick={() =>{
              changeTitle(id, article.Header)
              changeValueArticle(
                type === "edition" ? article.id : article.ArticleId,
                id,
                type
              )
             } }
          >
            {type === "edition" ? parseHtml(article.title).slice(9) : article.Header}
          </p>
        </Col>
      </Card>
    );
  });

export default function ModalArticle({
  id,
  active,
  toggle,
  changeValueArticle,
  changeTitle,
  parseHtml,
  categories
}) {
  const [webArticles, setWebArticles] = useState([]);
  const [type, setType] = useState("1");
  const [categoryId, setCategoryId] = useState(0);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [qtdEdition, setQtdEdition] = useState("60");
  const [loading, setLoading] = useState(false);

  const loadWebArticles = async (tipo) => {
    setLoading(true);
    try {
      if(tipo === "date"){
      const { data } = await Axios.get(
        `${baseHub}/homeArticles?date=${date}&amount=${qtdEdition}`
      );
      
      setWebArticles(data);
      setLoading(false);
    } else if (tipo==="editorias") {
      const { data } = await Axios.get(
        `${baseHub}/categoryArticles?categoryId=${categoryId}`
      );

      setWebArticles(data);
      setLoading(false);
    } else if (tipo === "search"){
      const { data } = await Axios.get(
        `${baseHub}/testSearch/?q=${search}`
      );
      setWebArticles(data);
      setLoading(false);
    }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };

  return (
    <Modal isOpen={active} toggle={toggle} key={id}>
      <ModalHeader toggle={toggle}>Artigos</ModalHeader>
      
      <ModalBody >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: type === "1" })}
              onClick={() => {
                setType("1")
                setWebArticles([])
              }}
            >
              Data
            </NavLink>
          </NavItem>
           
          <NavItem>
            <NavLink
              className={classnames({ active: type === "2" })}
              onClick={() => {
                setType("2")
                setWebArticles([])
              }}
            >
              Editorias
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: type === "3" })}
              onClick={() => {
                setType("3")
                setWebArticles([])
              }}
            >
              Pesquisa
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={type}>
          <TabPane tabId="1">
            <SearchArticleDate 
            setQtdEdition ={setQtdEdition}
            setDate = {setDate}
            loadWebArticles = {loadWebArticles}
            date={date}
            />
              {loading && <h5>Carregando artigos...</h5>}
              {renderArticles(
                webArticles,
                changeTitle,
                id,
                parseHtml,
                changeValueArticle,
                toggle,
                "web"
              )}
          </TabPane>
        </TabContent>
        
        <TabContent activeTab={type}>
          <TabPane tabId="2">
            <EditoriasSearchArticle 
            loadWebArticles = {loadWebArticles}
            categories={categories}
            setCategoryId={setCategoryId}
            />
            {loading && <h5>Carregando artigos...</h5>}
              {renderArticles(
                webArticles,
                changeTitle,
                id,
                parseHtml,
                changeValueArticle,
                toggle,
                "web"
              )}
          </TabPane>
        </TabContent> 

        <TabContent activeTab={type}>
          <TabPane tabId="3">
            <SearchArticle 
            loadWebArticles={loadWebArticles}
            setSearch={setSearch}
            />
            {loading && <h5>Carregando artigos...</h5>}
              {renderArticles(
                webArticles,
                changeTitle,
                id,
                parseHtml,
                changeValueArticle,
                toggle,
                "web"
              )}
          </TabPane>
        </TabContent>
   
      </ModalBody>
    </Modal>
  );
}
