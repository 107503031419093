import React from "react";
import { Button } from "reactstrap";
import { Tools } from "react-sketch";

export default function ListTools({ handleSelectedTool }) {
  return (
    <>
      {/*mostrando as opções do canvas e os elementos a serem preenchidos na marcação */}
      <h5>Ferramentas</h5>
      <hr />
      <div className="mw-flex">
      <Button
      className="btn btn-success"
          style={{ border: "none", 
          marginRight: '15px'
        }}
          value={Tools.Rectangle}
          onClick={handleSelectedTool}
        >
          Novo
        </Button>
        <Button
          className="btn btn-warning"
          style={{ 
            color:"#fff",
             border: "none" }}
          value={Tools.Select}
          onClick={handleSelectedTool}
        >
          Selecionar
        </Button>
      </div>
    </>
  );
}
