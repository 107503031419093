import React from "react";
import { Label } from "reactstrap";

class ListMarkers extends React.Component {

  render(){
      const {titles} = this.props
        return (
          <>
        <h5>Marcações</h5>
        {/*gerando todas as marcações na página, caso n esteja no modo edição */}
        <hr />
        {/*Fiz alterações aki, acrescentei -> && titles.elements.length */}
        {titles.elements &&
          titles.elements.length ? titles.elements.map(v => {
            return (
              <div key={v.id}>
                <Label>{v.title ? v.title : " Artigo "}</Label>
                <br />
              </div>
            );
          }
          ) 
          : "Nenhuma marcação foi criada, ou não foi possível carregar"} 
        </>
    ) 
  }  
}

export default ListMarkers