import React from "react";
import Logo from "../img/logo-gazeta-min-white.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

const BackButton = ({ page }) => {
  const path = page === "Edição" ? "/pages" : "/";

  return (
    <Button style={{ marginRight: 20 }} color="light" className="gza-btn">
      <Link to={path} style={{ textDecoration: "none", color: "#000" }}>
        Voltar
      </Link>
    </Button>
  );
};

export default class Header extends React.Component {
  openMenu = () => {
    if (
      document.getElementById("navbarNavAltMarkup").classList.contains("show")
    ) {
      document.getElementById("navbarNavAltMarkup").classList.remove("show");
    } else {
      document.getElementById("navbarNavAltMarkup").classList.add("show");
    }
  };

  render() {
    const { title, edit, remove, save, enableEdit } = this.props;
    return (
      <nav
        className="navbar navbar-dark gza-bg-primary fixed-top"
        style={{ justifyContent: "space-between" }}
      >
        <div className="container">
          <div>
            <Link to="/" style={{ marginRight: 20 }}>
              <img
                className="gza-logo"
                src={Logo}
                alt=""
                style={{ width: 300 }}
              />
            </Link>

            <span style={{ color: "#fff" }}>{title}</span>
          </div>

          <div>
            {enableEdit && save && (
              <Button onClick={save} color="success" className="gza-btn">
                Salvar
              </Button>
            )}{/* 
            {enableEdit && remove && (
              <Button onClick={remove} color="danger" className="gza-btn">
                Apagar
              </Button>
            )} */}
            {edit && (
              <Button onClick={edit} className="gza-btn">
                Editar
              </Button>
            )}
            {title && <BackButton page={title} />}
          </div>
        </div>
      </nav>
    );
  }
}
