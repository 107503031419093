import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

export default function SearchArticleDate({setQtdEdition,setDate,loadWebArticles,date}) {
  return (
    <Row className="mw-pad-b-10">
    <Col xs="5" style={{padding: "15px"}}>
      <Label>Quantidade de Matérias</Label>
      <Input type="select" name="materias" id="materias" 
      onChange={(e) => setQtdEdition(e.target.value)} 
      >
        <option>Selecionar</option>
        <option value="40">40 Matérias</option>
        <option value="60">60 Matérias</option>
        <option value="80">80 Matérias</option>
        <option value="100">100 Matérias</option>
      </Input>
    </Col>
    <Col xs="5" style={{display:"flex", flexDirection:"column", 
    justifyContent:"center", padding: "15px"}}>
      <Label>Data da edição</Label>
        <input style={{paddingBottom: "3px"}} type="date" name="edition" id="edition" value={date}
        onChange={(e) => {setDate(e.target.value)}} 
        />
    </Col>
    <Col xs="2" style={{display:"flex", alignItems:"flex-end", marginBottom:"10px", padding: "5px"}}>
      <Button className="btn btn-success"
          style={{ border: "none"}} 
        onClick={() => loadWebArticles("date")}
        >
          Buscar
        </Button>
    </Col>
    </Row>
  );
}
