import React from "react";
import { Container, Row, Col } from "reactstrap";
import { baseUrl } from "../utils/url";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import CardPage from "../components/CardPage";

export default class Home extends React.Component {
  state = {
    redirectEditor: false /* Variável para redirecionar para outras telas */,
    pages: [], //Variável que fica armazenado todas as páginas da edição escolhida
  };

  setRedirect = () => {
    this.setState({ redirectEditor: true }); //Setando o valor do redirect
  };

  async componentDidMount() {
    try{
      if (this.props.edition) {
        //Pegando a edição vindo de app
        const { edition } = this.props;
        /* Pegando todas as páginas referente a edição */
        const { data } = await Axios.get(
          `${baseUrl}/Page?editionId=${edition.editionId_}&format=json`
        );
        //Setando todas as páginas dessa edição no estado de page
        this.setState({ pages: data });
    }
    }catch(e){
      console.log("error" + e)
    }
}

  render() {
    /*selectPage: manda a page escolhida para app.js
      edition: vem de app.js, com todas as informações referente a edição escolhida 
        */
    const { selectPage, edition } = this.props;

    if (!this.props.edition.editionId_) return <Redirect to="/" />;

    if (this.state.redirectEditor) return <Redirect to="/editor" />;

    return (
      <>
        <Header title="Páginas" />
        <Container className="mw-pad-t-30 mw-pad-b-30 mw-pad-l-0 mw-pad-r-0 mw-m-t-50">
          <div style={{ border: "1px solid #e8e8e8" }}>
            <Row>
              {/*
              <Col>
                selectPage irá passar a página escolhida e a url da imagem para app
                                   setRedirect, altera o valor da variável de rota 
                <CardPage
                  selectPage={selectPage}
                  setRedirect={this.setRedirect}
                  number={1}
                  image={edition.coverPath_}
                  section={edition.editionId_}
                />
              </Col> */}
              {this.state.pages.map((v, i) => (
                <Col sm="4" key={`${v.number_}${v.pageName}`}>
                  <CardPage
                    selectPage={selectPage}
                    setRedirect={this.setRedirect}
                    number={v.pageName}  
                    image={v.path_}
                    section={edition.editionId_}
                    //name={edition.pageName}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Container>
        <Footer />
      </>
    );
  }
}
