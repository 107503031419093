import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

export default function SearchArticle({loadWebArticles, setSearch}) {
  return (
    <Row className="mw-pad-b-10">
      <Col xs="6" style={{padding: "15px"}}>
        <Label >Digite aqui sua pesquisa</Label>
        <Input type="text" name="search" id="search" placeholder="Digite sua busca"
        onChange={(e) => setSearch(e.target.value)} />
      </Col>

      <Col xs="2" style={{display:"flex", alignItems:"flex-end", padding: "0", 
      paddingLeft:"5px", paddingBottom:"15px"}}>
        <Button className="btn btn-success"
            style={{ border: "none"}} 
          onClick={() => loadWebArticles("search")}
          >
            Buscar
          </Button>
      </Col>
    </Row>
  );
}
