import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

export default function EditoriasSearchArticle({loadWebArticles, setCategoryId, categories}) {
  return (
    <Row className="mw-pad-b-10">
      <Col xs="5" style={{padding: "15px"}}>
        <Label>Categoria</Label>
        <Input type="select" name="category" id="category" onChange={(e) => setCategoryId(e.target.value)} >
          <option>Selecionar</option>
          {categories.map((category, id) => <option key={id}
            value={category.CategoryId}>
            {category.Name}
            </option>
          )}
        </Input>
      </Col>
      <Col xs="2" style={{display:"flex", alignItems:"flex-end", padding: "0", 
        paddingLeft:"5px", paddingBottom: "15px"}}>
        <Button className="btn btn-success"
            style={{ border: "none"}} 
          onClick={() => loadWebArticles("editorias")}
          >
            Buscar
          </Button>
      </Col>
    </Row>
  );
}
