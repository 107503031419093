import React from "react";
import { Container, Row, CardTitle, Col } from "reactstrap";
import { baseUrl } from "../utils/url";
import { Redirect, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

/*Classe home, responsável por mostrar todas as edições disponíveis */
export default class Home extends React.Component {
  state = {
    redirect: false /*Variável para redirecionar para outras telas */
  };

  /*Setando o valor do redirect */
  setRedirect() {
    this.setState({ redirect: true });
  }

  render() {
    // {/*Informações vindas do component APP:
    // editions: todas as edições disponíveis
    // selectEdition: função vinda de app para pegar a edição escolhida
    // */}
    const { editions, selectEdition } = this.props;
    return (
      <>
        <Header />
        <Container className="mw-pad-t-30 mw-m-t-50">
          {/*Se redirect for true, redireciona para o componente pages */}
          {this.state.redirect && <Redirect to="/pages" />}

          {/*Renderizando todas as edições na tela */}
          <Col md="12" className="mw-pad-0">
            <Row>
              {editions.map(v=> (
                <Col sm="3" className="mw-m-b-15" key={v.editionId_}>
                  <Col
                    md="12"
                    className="mw-pad-0"
                    onClick={() => {
                      selectEdition(v);
                      this.setRedirect();
                    }}>
                    <Link to="/pages">
                      <figure
                        className="col-12 mw-pad-0 gza-h-370 mw-wrapper"
                        style={{ border: "1px solid #eee" }}
                      >
                        <img
                          className="mw-fit-width mw-fit-height mw-object-fit mw-object-top"
                          width="100%"
                          src={`${baseUrl}/${v.coverPathThumb_}`}
                          alt="Card image cap"
                        />{" "}
                        {/*Exibindo imagem da edição */}
                      </figure>
                      <div
                        className="col-12 mw-pad-15 mw-bottom-absolute"
                        style={{ background: "#eee" }}
                      >
                        <CardTitle className="mw-m-b-0 gza-c-gray-2">
                          {/*Exibindo o número da edição */}
                          <span className="gza-f-12 gza-c-white gza-bg-primary gza-b-radius-3px gza-pad-1-5">
                            Edição: {v.editionId_}
                          </span>
                          <br /> 
                          {/*Exibindo o nome da edição */}
                          Nome: {v.name_}
                          <p>Data: {v.date_.split('T')[0]}</p>
                        </CardTitle>{" "}
                        
                      </div>
                    </Link>
                  </Col>
                </Col>
              ))}
            </Row>
          </Col>
        </Container>
        <Footer />
      </>
    );
  }
}
